import React, { Fragment } from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import TopImage from '../components/TopImage'
import Card from '../components/Card'

import ServiziImg from '../images/servizi.jpg'

const paginaServizio = {
  titolo: 'Servizi',
  imgUrl: ServiziImg,
  servizi: [
    {
      id: 1,
      preTitolo: 'Nazionali',
      nome: 'National Express',
      descrizione:
        'La nostra grande flessibilità ci consente di spedire fino a 500 kg e di venire sempre incontro alle tue esigenze.',
      linkUrl: '/servizi/national-express',
    },
    {
      id: 2,
      preTitolo: 'Nazionali',
      nome: 'Safe Plus',
      descrizione:
        'Il prodotto per le spedizioni di valore che garantisce, oltre alla consueta tracciabilità, anche un rigoroso monitoraggio.',
      linkUrl: '/servizi/safe-plus',
    },
    {
      id: 3,
      preTitolo: 'Internazionali',
      nome: 'Parcel',
      descrizione:
        "Servizio camionistico “door to door” d'eccellenza. Collega 30+ paesi europei con consegne fino a 40 kg in 48-72 ore.",
      linkUrl: '/servizi/parcel',
    },
    {
      id: 4,
      preTitolo: 'Internazionali',
      nome: 'Express',
      descrizione:
        'Servizio aereo espresso che consente di spedire pacchi e documenti in tutto il mondo con consegna a domicilio.',
      linkUrl: '/servizi/express',
    },
    {
      id: 5,
      preTitolo: 'Internazionali',
      nome: 'Logistics',
      descrizione:
        'Servizio camionistico o aereo per merci senza limiti di peso e dimensioni con tempi di resa di 72-96 ore.',
      linkUrl: '/servizi/logistics',
    },
    {
      id: 6,
      preTitolo: 'Accessori',
      nome: 'Servizi accessori',
      descrizione:
        "Scopri l'intera la gamma dei nostri servizi accessori per personalizzare al massimo ogni tua spedizione.",
      linkUrl: '/servizi/servizi-accessori',
    },
  ],
}

const Servizi = () => {
  return (
    <Layout>
      <SEO
        title="Servizi"
        description="Mediterranea Express - Corriere GLS Sicilia - Tutti i servizi nazionali, internazionali e accessori"
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg lg:shadow-xl">
        <TopImage imgUrl={paginaServizio.imgUrl} />
        <div className="flex flex-wrap py-12 px-6">
          {paginaServizio.servizi.map((servizio) => {
            return (
              <Fragment key={servizio.id}>
                <Card oggetto={servizio} />
              </Fragment>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default Servizi
